import React from 'react';
import styled from 'styled-components';
import {Table} from 'antd';

function AccessLog(props) {
  const {service, records} = props;
  return (
    <Wrapper>
      <h3 style={{color: '#888'}}>{service.display_name.toUpperCase()}</h3>

      {service.display_name === 'jstorage' && (
        <Table
          rowKey={'month'}
          dataSource={records}
          columns={[
            {
              title: 'MONTH',
              key: 'month',
              render: (_, record) => <div>{record.month}</div>,
            },
            {
              title: 'READ',
              key: 'read',
              render: (_, record) => <div>{record.read || 0}</div>,
            },
            {
              title: 'WRITE',
              key: 'write',
              render: (_, record) => <div>{record.write || 0}</div>,
            },
          ]}
        />
      )}

      {service.display_name === 'notification' && (
        <Table
          rowKey={'month'}
          dataSource={records}
          columns={[
            {
              title: 'MONTH',
              key: 'month',
              render: (_, record) => <div>{record.month}</div>,
            },
            {
              title: 'EMAIL',
              key: 'email',
              render: (_, record) => <div>{record.email || 0}</div>,
            },
            {
              title: 'SMS',
              key: 'sms',
              render: (_, record) => <div>{record.sms || 0}</div>,
            },
            {
              title: 'PUSH',
              key: 'push',
              render: (_, record) => <div>{record.publish || 0}</div>,
            },
          ]}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

export default AccessLog;
