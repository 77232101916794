import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import ServiceRoutes from '../ServiceRoutes';
import * as AppContext from '../AppContext';
import * as Widget from '../components/Widget';
import InfoCard from '../components/InfoCard';
import * as Ant from 'antd';
import AccessLog from '../components/AccessLog';

function SummaryPage(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [access, setAccess] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        const accList = [];
        for (const service of user.services) {
          const records = await actions.fetchAccessLog(service);
          accList.push({
            service,
            records: records.reverse(),
          });
        }
        setAccess(accList);
      } catch (ex) {
        console.warn('fetch access log fail', ex);
      } finally {
        actions.setLoading(false);
      }
    }

    if (user) {
      fetchData();
    }
  }, [user, actions]);

  if (!user) {
    return null;
  }

  const articleService = user.services.find(
    (sv) => sv.service_name === 'jstorage',
  );

  return (
    <Wrapper>
      <Widget.MaxWidthCenter>
        <h2>Summary</h2>
      </Widget.MaxWidthCenter>

      <Widget.MaxWidthCenter margin={30}>
        <InfoCard>
          <h2>User Profile</h2>
          {user && (
            <div>
              <Widget.FlexRow>
                <div style={{width: 120, color: '#ccc'}}>Username</div>
                <div>{user.username}</div>
              </Widget.FlexRow>
              <Widget.FlexRow>
                <div style={{flex: 1}} />
                <Ant.Button
                  type="primary"
                  onClick={() => navigate('/resetpwd')}>
                  重設密碼
                </Ant.Button>
              </Widget.FlexRow>
            </div>
          )}
        </InfoCard>
      </Widget.MaxWidthCenter>

      <Widget.MaxWidthCenter margin={30}>
        <InfoCard>
          <h2>Services</h2>
          {user.services.map((service) => {
            const serviceRoute = ServiceRoutes[service.service_name];
            return (
              <Ant.Button
                key={service.service_name}
                type="primary"
                style={{margin: '0 10px 10px 0'}}
                onClick={() => {
                  navigate(serviceRoute.route);
                }}>
                {serviceRoute.label}
              </Ant.Button>
            );
          })}

          {articleService ? (
            <Ant.Button
              key="articles"
              type="primary"
              style={{margin: '0 10px 10px 0'}}
              onClick={() => {
                navigate(ServiceRoutes['articles'].route);
              }}>
              {ServiceRoutes['articles'].label}
            </Ant.Button>
          ) : null}

          {user && (
            <Ant.Button
              key="issuers"
              type="primary"
              style={{margin: '0 10px 10px 0'}}
              onClick={() => {
                navigate('/issuers');
              }}>
              Issuer
            </Ant.Button>
          )}

          {user && (
            <Ant.Button
              key="create_user"
              onClick={() => navigate('/createadminuser')}>
              Create Admin User
            </Ant.Button>
          )}
        </InfoCard>
      </Widget.MaxWidthCenter>

      <Widget.MaxWidthCenter margin={30}>
        <InfoCard>
          <h2>Access Log</h2>

          {access.map(({service, records}) => (
            <AccessLog
              key={service.service_name}
              service={service}
              records={records}
            />
          ))}
        </InfoCard>
      </Widget.MaxWidthCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  min-height: 100vh;
`;

export default SummaryPage;
